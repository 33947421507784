import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/AuthContext';

function PaymentCancelled() {
  const navigate = useNavigate();
  const { user } = useAuth();

  // Add a check for user authentication
  if (!user) {
    return (
      <div className="pricing-page">

      <div className="hero hero-1">
        <div className="container textbox">
          <h1>Session Expired</h1>
          <p>Please log in again to continue.</p>
          <button 
            className="button"
            onClick={() => navigate('/login')}
          >
            Log In
          </button>
        </div>
      </div>
      </div>
    );
  }

  return (
    <div className="pricing-page">
    <div className="hero hero-1">
      <div className="container textbox">
        <h1>Payment Cancelled</h1>
        <p>Your payment was cancelled or didn't complete.</p>
        <p>No charges were made to your account.</p>
        
        <div className="button-group">
          <button 
            className="button"
            onClick={() => navigate('/payment')}
          >
            Try Again
          </button>
          <button 
            className="button secondary"
            onClick={() => navigate('/account')}
          >
            Go to Account
          </button>
        </div>
      </div>
    </div>
    </div>
  );
}

export default PaymentCancelled;