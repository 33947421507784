import { userPool } from '../authentication/AuthContext';

const makeResearchRequest = async (endpoint, { method = 'GET', body = null } = {}) => {
  const user = userPool.getCurrentUser();
  if (!user) {
    throw new Error('No user logged in');
  }

  const session = await new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) {
        reject(new Error('Authentication failed'));
      }
      else resolve(session);
    });
  });

  const response = await fetch(`${process.env.REACT_APP_RESEARCH_API_URL}${endpoint}`, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${session.getIdToken().getJwtToken()}`
    },
    mode: 'cors',
    body: body ? JSON.stringify(body) : null
  });

  if (!response.ok) {
    const text = await response.text();
    console.error('Research API Error:', {
      status: response.status,
      body: text
    });
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  return response.json();
};

export const researchApi = {
  createResearch: async (userID, researchQuestion) => {
    try {
      return await makeResearchRequest('/research/create', {
        method: 'POST',
        body: {
          userId: userID,
          surveyDescription: researchQuestion
        }
      });
    } catch (error) {
      console.error('Research API Error:', error);
      throw new Error('Failed to create research');
    }
  },

  getResearch: async (userID) => {
    try {
      return await makeResearchRequest(`/research/${userID}`);
    } catch (error) {
      throw new Error('Failed to get research');
    }
  },

  updateQuestions: async (userID, researchID, questions) => {
    try {
      return await makeResearchRequest('/research/update_questions', {
        method: 'POST',
        body: {
          userID,
          researchID,
          questions: questions.map(q => ({
            questionID: q.id,
            researchID,
            userID,
            questionText: q.text,
            questionType: q.type,
            responseOptions: q.options,
            questionStatus: 'noResponses'
          }))
        }
      });
    } catch (error) {
      throw new Error('Failed to update questions');
    }
  },

  createPersonas: async (researchID, filters) => {
    try {
      const body = {
        researchID,
        numberOfPersonas: filters.count,
        filters: {
          region: filters.region,
          F_AGECAT: [
            ["GreaterThan", filters.ageRange.min],
            ["LessThan", filters.ageRange.max]
          ],
          F_INC_SDT1: [
            ["GreaterThan", filters.incomeRange.min],
            ["LessThan", filters.incomeRange.max]
          ]
        }
      };
      
      return await makeResearchRequest('/personas/generate', {
        method: 'POST',
        body
      });
    } catch (error) {
      throw new Error('Failed to create personas');
    }
  },

  getPersonas: async (researchID) => {
    try {
      return await makeResearchRequest(`/personas/${researchID}`);
    } catch (error) {
      throw new Error('Failed to fetch personas');
    }
  },

  getResponses: async (researchID) => {
    try {
      return await makeResearchRequest(`/responses/${researchID}`);
    } catch (error) {
      throw new Error('Failed to fetch responses');
    }
  },

  createResponses: async (researchID, userID, questionIDs, personaIDs) => {
    try {
      return await makeResearchRequest('/responses/create', {
        method: 'POST',
        body: {
          researchID,
          userID,
          questionIDs,
          personaIDs
        }
      });
    } catch (error) {
      throw new Error('Failed to create responses');
    }
  },

  removeResearch: async (userID, researchID) => {
    try {
      return await makeResearchRequest('/research/remove', {
        method: 'POST',
        body: {
          userID,
          researchID
        }
      });
    } catch (error) {
      throw new Error('Failed to remove research');
    }
  }
};