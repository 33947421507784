import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

export function PaymentForm({ amount, onSuccess, onError, selectedPlan, currentSubscription }) {
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe) return;

    setLoading(true);
    try {
      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: await onSuccess() // This will now expect a session ID
      });

      if (error) {
        onError(error);
      }
    } catch (err) {
      onError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='textbox container'>
      <form onSubmit={handleSubmit} style={{ maxWidth: '70%', margin: '0 auto' }}>
        {amount > 0 && (
          <p>Amount to pay: €{amount}</p>
        )}
        <button className="button standout-button" type="submit" disabled={!stripe || loading}>
          {loading ? 'Processing...' : 'Proceed to Payment'}
        </button>
      </form>
    </div>
  );
}