import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../authentication/AuthContext';
import { paymentApi } from '../services/paymentApi';

function PaymentSuccess() {
  const [status, setStatus] = useState('loading');
  const location = useLocation();
  const navigate = useNavigate();
  const { updateUserAttributes, user } = useAuth();

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        // First check if we're authenticated
        if (!user) {
          console.log('No user found, redirecting to login');
          navigate('/login');
          return;
        }

        const params = new URLSearchParams(location.search);
        const sessionId = params.get('session_id');

        if (!sessionId) {
          console.log('No session ID found');
          setStatus('error');
          return;
        }

        // Get updated user details
        try {
          const userDetails = await paymentApi.getUserDetails();
          console.log('User details received:', userDetails);
          
          if (userDetails && userDetails.credits !== undefined && userDetails.subscription) {
            try {
              await updateUserAttributes();
              setStatus('success');
              
              // Redirect to account page after 3 seconds
              setTimeout(() => {
                navigate('/account');
              }, 3000);
            } catch (updateError) {
              console.error('Error updating user attributes:', updateError);
              // Even if updating attributes fails, the payment was still successful
              setStatus('success');
              setTimeout(() => {
                navigate('/account');
              }, 3000);
            }
          } else {
            throw new Error('Invalid user details received');
          }
        } catch (error) {
          console.error('Error fetching user details:', error);
          setStatus('error');
        }

      } catch (error) {
        console.error('Payment verification failed:', error);
        setStatus('error');
      }
    };

    checkPaymentStatus();
  }, [location, navigate, updateUserAttributes, user]);

  // Add a check for user authentication in the render
  if (!user) {
    return (
    <div className="pricing-page">
      <div className="hero hero-1">
        <div className="container textbox">
          <h1>Session Expired</h1>
          <p>Please log in again to verify your payment.</p>
          <button 
            className="button"
            onClick={() => navigate('/login')}
          >
            Log In
          </button>
        </div>
      </div>
      </div>
    );
  }

  return (
    <div className="pricing-page">
    <div className="hero hero-1">
      <div className="container textbox">
        {status === 'loading' && (
          <>
            <h1>Processing your payment...</h1>
            <p>Please wait while we confirm your payment.</p>
          </>
        )}
        
        {status === 'success' && (
          <>
            <h1>Payment Successful!</h1>
            <p>Thank you for your purchase. Your account has been updated.</p>
            <p>You will be redirected to your account page in a few seconds...</p>
          </>
        )}
        
        {status === 'error' && (
          <>
            <h1>Something went wrong</h1>
            <p>We couldn't verify your payment. Please contact support if this persists.</p>
            <button 
              className="button"
              onClick={() => navigate('/account')}
            >
              Go to Account
            </button>
          </>
        )}
      </div>
      </div>
      </div>
  );
}

export default PaymentSuccess;