import React, { useState } from 'react';
import { useAuth } from '../authentication/AuthContext';
import { CREDIT_COSTS } from './PaymentUtils';
import { PaymentForm } from './PaymentForm';
import { StripeWrapper } from '../components/StripeWrapper';
import { paymentApi } from '../services/paymentApi';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

function CreditTopup({ creditPackages }) {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const { updateUserAttributes, credits, subscription, user } = useAuth();
  const stripe = useStripe();
  const navigate = useNavigate();

  // Calculate price based on subscription type and package
  const calculatePrice = (pkg) => {
    const baseCost = subscription === 'PREMIUM' 
      ? CREDIT_COSTS.PREMIUM.premiumResearch / 3
      : CREDIT_COSTS.BASIC.premiumResearch / 3;
    
    return (baseCost * pkg.amount * pkg.priceVar).toFixed(2);
  };

  const calculatePricePerCredit = (pkg) => {
    const totalPrice = calculatePrice(pkg);
    return (totalPrice / pkg.amount).toFixed(2);
  };

  const handlePayment = async () => {
    if (!selectedPackage || !stripe) return;
    
    try {
      let amount, creditsToAdd;
      
      if (typeof selectedPackage === 'string') {
        amount = subscription === 'PREMIUM' 
          ? (selectedPackage === 'basicResearch' ? CREDIT_COSTS.PREMIUM.basicResearch : CREDIT_COSTS.PREMIUM.premiumResearch)
          : (selectedPackage === 'basicResearch' ? CREDIT_COSTS.BASIC.basicResearch : CREDIT_COSTS.BASIC.premiumResearch);
        creditsToAdd = selectedPackage === 'basicResearch' ? 1 : 3;
      } else {
        amount = calculatePrice(selectedPackage);
        creditsToAdd = selectedPackage.amount;
      }

      // Get the checkout session ID
      const sessionId = await paymentApi.createCheckoutSession(amount, creditsToAdd, "addCredits");
      return sessionId; // This will be used by PaymentForm to redirect
      
    } catch (error) {
      console.error('Payment Error:', error);
      if (error.response) {
        console.error('Response:', await error.response.text());
      }
      throw error; // Let PaymentForm handle the error
    }
  };

  return (
    <StripeWrapper>
      <div className="credit-topup">
        <div className="container">
          <h2>Credit costs for your {subscription} subscription:</h2>
          <div className="pricing-container">
            <div 
              className={`pricing-card ${selectedPackage === 'basicResearch' ? 'selected' : ''}`}
              onClick={() => setSelectedPackage('basicResearch')}
            >
              <h3>Basic Research</h3>
              <p className="credits">1 Credit</p>
              <p className="price">€{subscription === 'PREMIUM' ? '1.00' : '2.00'}</p>
              <p className="value">€{subscription === 'PREMIUM' ? '1.00' : '2.00'} per credit</p>
            </div>
            <div 
              className={`pricing-card ${selectedPackage === 'premiumResearch' ? 'selected' : ''}`}
              onClick={() => setSelectedPackage('premiumResearch')}
            >
              <h3>Premium Research</h3>
              <p className="credits">3 Credits</p>
              <p className="price">€{subscription === 'PREMIUM' ? '2.00' : '5.00'}</p>
              <p className="value">€{subscription === 'PREMIUM' ? '0.67' : '1.67'} per credit</p>
            </div>
          </div>
        </div>

        <div className="container">
          <h2>Or select one of our credit packages:</h2>
          <div className="pricing-container">
            {creditPackages.map((pkg, index) => (
              <div 
                key={index}
                className={`pricing-card ${selectedPackage === pkg ? 'selected' : ''}`}
                onClick={() => setSelectedPackage(pkg)}
              >
                <h3>{pkg.description}</h3>
                <p className="credits">{pkg.amount} Credits</p>
                <p className="price">€{calculatePrice(pkg)}</p>
                <p className="value">€{calculatePricePerCredit(pkg)} per credit</p>
                <p className="savings">Save {((1 - pkg.priceVar) * 100).toFixed(0)}%</p>
              </div>
            ))}
          </div>
        </div>
        
        <PaymentForm 
          amount={selectedPackage ? calculatePrice(selectedPackage) : 0}
          onSuccess={handlePayment}
          onError={(error) => alert('Payment failed: ' + (error.message || 'Unknown error'))}
        />
      </div>
    </StripeWrapper>
  );
}

export default CreditTopup;