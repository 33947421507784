import React, { useState } from 'react';
import { useFormSubmit } from '../services/useFormSubmit';
import '../styles/ResearchRequestPage.css';

function ResearchRequestPage() {
  const { submitStatus, handleSubmit } = useFormSubmit();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFormSubmit = async (e) => {
    setIsSubmitting(true);
    await handleSubmit(e, 'research-request-form');
    setIsSubmitting(false);
  };

  return (
    <div className="research-request-page">
      <div className="hero hero-1 top-hero">
        <h1>Interested in Our AI-Driven Market Research?</h1>
        <p>So you're interested in the costs, well why not reach out and tell us about your research needs? We'll discuss pricing options tailored to your project.</p>
    </div>

      <div className="form-section">
        <div className="container">
          <form
            id="research-request-form"
            action="https://script.google.com/macros/s/AKfycbz3X8mRhapnoT9XdfOdhJjXJhO2Q5X0Sz-0FbLsjCK81WlB0rOHiTP1ib3I6acjfC5dmg/exec"
            method="POST"
            className="research-form"
            onSubmit={handleFormSubmit}
          >
            <div className="form-group">
              <h3>Company Information</h3>
              <input type="text" name="CompanyName" placeholder="Company Name" required />
              <input type="text" name="FirstName" placeholder="First Name" required />
              <input type="text" name="LastName" placeholder="Last Name" required />
              <input type="email" name="Email" placeholder="Email Address" required />
              <input type="tel" name="Phone" placeholder="Phone Number" />
              <p className="field-hint">Tell us about your company's background and main activities</p>
              <textarea name="AboutCompany" placeholder="What does your company do? What markets do you serve?" required></textarea>
            </div>

            <div className="form-group">
              <h3>Research Details</h3>
              <p className="field-hint">Be specific about what insights you want to gain from this research</p>
              <textarea 
                name="ResearchObjectives" 
                placeholder="What key questions do you want answered? What decisions will this research inform?" 
                required
              ></textarea>
              <p className="field-hint">Describe the challenges you're facing that prompted this research</p>
              <textarea 
                name="CurrentChallenges" 
                placeholder="What specific problems are you trying to solve? How do these challenges affect your business operations or decision-making?"
                required
              ></textarea>
            </div>

            <div className="form-group">
              <h3>Target Demographics</h3>
              <p className="field-hint">Define your ideal research participants as specifically as possible</p>
              <input type="text" name="AgeRange" placeholder="Age Range (e.g., 25-45, 50+)" />
              <input type="text" name="Gender" placeholder="Gender Distribution (if relevant)" />
              <input type="text" name="Location" placeholder="Geographic Location (specific regions, urban/rural)" />
              <input type="text" name="Occupation" placeholder="Occupation/Professional Role (e.g., homeowners, IT professionals)" />
              <p className="field-hint">Include any specific characteristics that define your target group (e.g., 'owns an electric vehicle', 'has a mortgage', 'uses online banking')</p>
              <textarea name="OtherDemographics" placeholder="Other defining characteristics of your target audience"></textarea>
            </div>

            <div className="form-group">
              <h3>Additional Information</h3>
              <p className="field-hint">Tell us about any existing research or data that could inform this project</p>
              <textarea name="ExistingData" placeholder="What existing data, research, or customer feedback do you have?"></textarea>
              <p className="field-hint">Any other context that could help us understand your needs better</p>
              <textarea name="AdditionalComments" placeholder="Other relevant information or specific requirements"></textarea>
            </div>

            <button 
              type="submit" 
              className="button" 
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending Request...' : 'Submit Request'}
            </button>
            
            {submitStatus.message && (
              <div className={submitStatus.isError ? 'error-message' : 'success-message'}>
                {submitStatus.isError 
                  ? submitStatus.message 
                  : <>
                      <p>{submitStatus.message}</p>
                      <p>We're excited to work with you! Our team will review your request and get back to you as soon as possible.</p>
                    </>
                }
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResearchRequestPage;