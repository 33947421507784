import React, { useState, useRef } from 'react';
import { useAuth } from '../authentication/AuthContext';
import '../styles/HomePage.css';
import '../index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../i18n';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import 'swiper/css/autoplay';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { useFormSubmit } from '../services/useFormSubmit';

function HomePage() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(navigator.language);
  }, []);

  const { user } = useAuth();
  const [isPaused, setIsPaused] = useState(false);
  const swiperRef = useRef(null);
  const { submitStatus, handleSubmit } = useFormSubmit();

  const toggleAutoplay = () => {
    if (isPaused) {
      swiperRef.current.swiper.autoplay.start();
    } else {
      swiperRef.current.swiper.autoplay.stop();
    }
    setIsPaused(!isPaused);
  };

  return (
    <div className="home-page">
      <div className="top-hero hero hero-1">
        <div className="hero-grid-item">
          <h1>Get consumer insights in </h1>
          <h1>minutes, not weeks</h1>
          <h3>The future of focus groups is here.</h3>
          <h3>Get instant customer feedback</h3>
          <h3>through the power of AI.</h3>
          
          <div className="button-container">
            <a href="/about" className="button secondary-button">How others use it</a>
            {user ? (
              <a href="/research" className="standout-button button">Start research</a>
            ) : (
              <a href="/login" className="standout-button button">Try it for free! &rarr;</a>
            )}
          </div>
        </div>
       
      </div>

      <div className='hero hero-1'>
        <h2>How does it work?</h2>
        <img src="/how-to-picture.jpg" alt="How to" className="how-to-image" />

        <a href="/researchrequest" className="standout-button button">Request research</a>

      </div>

        {/* 
        <div className="hero hero-1">
          <h2>How does it work?</h2>
          <div>
            <Swiper
              ref={swiperRef}
              modules={[Pagination, Navigation, Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              speed={500}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={true}
            >
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="container">
                    <img src={require('../components/promotions/process_step1.png')} alt="Services Promotion" className="image-content" />
                  </div>
                  <div className="textbox container">
                    <h3>1. Create Your Research Topic:</h3>
                    <p>Start by defining what you want to research - this could be a product, service, or concept you want to understand better.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="container">
                    <img src={require('../components/promotions/process_step2.png')} alt="Services Promotion" className="image-content" />
                  </div>
                  <div className="textboxcontainer">
                    <h3>2. Design Your Survey:</h3>
                    <p>Create your research questions with our flexible question builder. Choose from open-ended, multiple choice, or true/false questions to gather the insights you need.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="container">
                    <img src={require('../components/promotions/process_step3.png')} alt="Services Promotion" className="image-content" />
                  </div>
                  <div className="textboxcontainer">
                    <h3>3. Define Your Target Audience:</h3>
                    <p>Use our demographic filters to specify your ideal respondents - including age range, location, and income level. Our AI will generate detailed personas matching your criteria.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="container">
                    <img src={require('../components/promotions/process_step4.png')} alt="Services Promotion" className="image-content" />
                  </div>
                  <div className="textboxcontainer">
                    <h3>4. Get AI-Generated Responses:</h3>
                    <p>Our AI simulates responses from your created personas, providing realistic feedback based on their demographic profiles and characteristics.</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="slide-content content-grid-2">
                  <div className="container">
                    <img src={require('../components/promotions/process_step5.png')} alt="Services Promotion" className="image-content" />
                  </div>
                  <div className="textboxcontainer">
                    <h3>5. Analyze Results:</h3>
                    <p>Review responses across all your personas and use our AI analysis tools to uncover key insights and patterns in your research data.</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
            <button className="button" onClick={toggleAutoplay}>
              {isPaused ? '⏵' : '⏸'}
            </button>
          </div>
        </div>  
        */}

        <div className="hero hero-3">
          <h2>Meet our personas</h2>
            <Swiper
              modules={[Pagination, Navigation, Autoplay]}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false
              }}
              speed={1500}
              initialSlide={2}
              spaceBetween={30}
              slidesPerGroup={1}
              loop={true}
              pagination={{
                clickable: true,
              }}
              navigation={false}
              breakpoints={{
                520: { slidesPerView: 1 },
                600: { slidesPerView: 2 },
                1100: { slidesPerView: 3 },
                1600: { slidesPerView: 4 }
              }}
            >      
              <SwiperSlide>
                <div className="slide-content content-grid-1 container" style={{ marginBottom: "3rem" }}>
                    <div className="image-content">
                      <div className="card-image">
                        <img src={require('../components/personas/Leyla.jpeg')} alt="Leyla's profile" className="img" />
                      </div>
                    </div>
                    <div>
                    <h3 className="name">Leyla</h3>
                      <p className="description">
                        Medical Assistant <br/>
                        29 years old<br/>
                        €38,584 per year<br/>
                        Single, no children<br/>
                        Follows healthcare innovation
                      </p>
                    </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-content content-grid-1 container">
                    <div className="image-content">
                      <div className="card-image">
                        <img src={require('../components/personas/Mark.jpeg')} alt="Mark's profile" className="img" />
                      </div>
                    </div>
                    <div>
                    <h3 className="name">Mark</h3>
                      <p className="description">
                        Software Developer <br/>
                        34 years old<br/>
                        €65,000 per year<br/>
                        Married, 2 children<br/>
                        Tech-savvy early adopter
                      </p>
                    </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-content content-grid-1 container">
                    <div className="image-content">
                      <div className="card-image">
                        <img src={require('../components/personas/Sarah.jpeg')} alt="Sarah's profile" className="img" />
                      </div>
                    </div>
                    <div>
                    <h3 className="name">Sarah</h3>
                      <p className="description">
                        Marketing Manager <br/>
                        41 years old<br/>
                        €72,000 per year<br/>
                        Married, 1 child<br/>
                        Focuses on digital transformation
                      </p>
                    </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-content content-grid-1 container">
                    <div className="image-content">
                      <div className="card-image">
                        <img src={require('../components/personas/Abdel.jpeg')} alt="Abdel's profile" className="img" />
                      </div>
                    </div>
                    <div>
                    <h3 className="name">Abdel</h3>
                      <p className="description">
                        Small Business Owner <br/>
                        45 years old<br/>
                        €55,000 per year<br/>
                        Married, 2 children<br/>
                        Passionate about their customers
                      </p>
                    </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-content content-grid-1 container">
                    <div className="image-content">
                      <div className="card-image">
                        <img src={require('../components/personas/Emma.jpeg')} alt="Emma's profile" className="img" />
                      </div>
                    </div>
                    <div>
                    <h3 className="name">Emma</h3>
                      <p className="description">
                        UX Designer <br/>
                        27 years old<br/>
                        €48,000 per year<br/>
                        Single, no children<br/>
                        Focused on user accessibility
                      </p>
                    </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-content container">
                    <div className="image-content">
                      <div className="card-image">
                        <img src={require('../components/personas/David.jpeg')} alt="David's profile" className="img" />
                      </div>
                    </div>
                    <div>
                    <h3 className="name">David</h3>
                      <p className="description">
                        Product Manager <br/>
                        38 years old<br/>
                        €82,000 per year<br/>
                        Married, 3 children<br/>
                        Expert in market research
                      </p>
                    </div>
                </div>
              </SwiperSlide>
            </Swiper>
        </div>

          <div className="hero hero-1">

            <h2>Interested in InstantFlows?</h2>
            <div className="textbox">
              <p>Sign up to stay updated on our progress!</p>
              <form 
                id="interest-form" 
                action="https://script.google.com/macros/s/AKfycbxr330lHfFdEuwlIhhJAfi-NNTT4vXd7Xbgy58V4_pYxGkVgKelK9vU0wOuRmiShb_u6w/exec" 
                method="POST" 
                className="interest-form"
                onSubmit={(e) => handleSubmit(e, 'interest-form')}
              >
                <input type="text" name="Name" placeholder="Your Name" required />
                <input type="email" name="Email" placeholder="Your Email" required />
                <button type="submit" className="button">Keep Me Updated</button>
                {submitStatus.message && (
                  <div className={submitStatus.isError ? 'error-message' : 'success-message'}>
                    {submitStatus.message}
                  </div>
                )}
              </form>
            </div>
          </div>
      </div>
  );
}

export default HomePage;
