import React, { useState, useEffect } from 'react';
import { useAuth } from '../authentication/AuthContext';
import { PaymentForm } from './PaymentForm';
import { StripeWrapper } from '../components/StripeWrapper';
import { paymentApi } from '../services/paymentApi';
import { useStripe } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

function SubscriptionPayment({ selectedPlan, subscriptionTypes, currentSubscription }) {
  const [selectedPlanState, setSelectedPlanState] = useState(selectedPlan || 'BASIC');
  const [error, setError] = useState(null);
  const { user, credits, updateUserAttributes, subscription } = useAuth();
  const stripe = useStripe();
  const navigate = useNavigate();

  const handlePayment = async () => {
    try {
      console.log('Starting payment process:', {
        selectedPlan: selectedPlanState
      });
      
      const response = await paymentApi.createSubscriptionSession(selectedPlanState);

      // Check if there's a pending cancellation
      if (response.pendingCancellation) {
        const keepSubscription = window.confirm(
          `Your current subscription is scheduled to cancel on ${new Date(response.cancelAt).toLocaleDateString()}. ` +
          `Would you like to keep your subscription active and switch to the ${selectedPlanState} plan?`
        );

        if (keepSubscription) {
          // Remove the cancellation and then proceed with plan change
          await paymentApi.removeCancellation(response.subscriptionId);
        } else {
          // User wants to keep the cancellation
          alert('Please try changing your plan after your current subscription ends.');
          return null;
        }
      }

      return response.sessionId;
      
    } catch (error) {
      console.error('Payment process error:', {
        error,
        message: error.message,
        stack: error.stack
      });
      throw error; // Let PaymentForm handle the error
    }
  };

  const handleCancellation = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription? You will lose access to your subscription features at the end of your current billing period.')) {
      try {
        const response = await paymentApi.cancelSubscription();
        alert('Your subscription will be cancelled at the end of the current billing period.');
        navigate('/account');
      } catch (error) {
        console.error('Cancellation failed:', error);
        alert('Failed to cancel subscription: ' + error.message);
      }
    }
  };

  useEffect(() => {
    console.log('Subscription state:', {
      subscription,
      selectedPlanState,
      selectedPlan
    });
  }, [currentSubscription, selectedPlanState, selectedPlan, subscription]);

  if (!user) {
    return <div>Loading user data...</div>;
  }

  return (
    <StripeWrapper>
      <div>
        <div className="pricing-container">
          {Object.entries(subscriptionTypes)
            .filter(([key]) => key !== 'FREE')
            .map(([key, value]) => (
              <div 
                key={key}
                className={`pricing-card ${selectedPlanState === key ? 'selected' : ''}`}
                onClick={() => setSelectedPlanState(key)}
              >
                <h3>{value.name}</h3>
                <p className="price">€{value.price}/month</p>
                <p className="billing-cycle">Billed monthly from today</p>
                <p className="credits-info">Credits reset every Sunday</p>
                <ul>
                  {value.features.map((feature, index) => (
                    <li key={index}>{feature}</li>
                  ))}
                </ul>
              </div>
            ))} 
        </div>
        <div>
        <PaymentForm 
          amount={subscriptionTypes[selectedPlanState]?.price || 0}
          onSuccess={handlePayment}
          onError={(error) => alert('Payment failed: ' + (error.message || 'Unknown error'))}
        />
        {(currentSubscription === 'BASIC' || currentSubscription === 'PREMIUM') && (
          <div className="container textbox">
            <h3>Cancel your subscription?</h3>
            <p>Monthly billing will be stopped and your subscription will remain active until the end of the current billing period.</p>
            <button 
              className="button"
              onClick={handleCancellation}
            >
              Cancel Subscription
            </button>
          </div>
        )}
        </div>
      </div>
    </StripeWrapper>
  );
}

export default SubscriptionPayment;