import { createContext, useContext, useState, useEffect } from 'react';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { paymentApi } from '../services/paymentApi';

const AuthContext = createContext();

export const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [email, setEmail] = useState(null);
  const [credits, setCredits] = useState(0);
  const [subscription, setSubscription] = useState('FREE');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sub, setSub] = useState(null);


  useEffect(() => {
    const loadUser = async () => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser) {
        try {
          const session = await new Promise((resolve, reject) => {
            cognitoUser.getSession((err, session) => {
              if (err) {
                console.error('Session error:', err);
                reject(err);
              }
              else resolve(session);
            });
          });

          if (session) {
            setUser(cognitoUser);  // Set the entire user object
            
            cognitoUser.getUserAttributes((err, attributes) => {
              if (err) {
                console.error('Error loading user attributes:', err);
                return;
              }

              const userAttributes = {};
              attributes.forEach(attr => {
                userAttributes[attr.Name] = attr.Value;
              });

              setFirstName(userAttributes['given_name'] || null);
              setEmail(userAttributes['email'] || null);
              setCredits(Number(userAttributes['custom:credits'] || 0));
              setSubscription(userAttributes['custom:subscription'] || 'FREE');
              setSubscriptionStartDate(userAttributes['custom:subscriptionStartDate'] || null);
              setSub(userAttributes['sub'] || null);
            });
          }
        } catch (error) {
          console.error('Error loading user:', error);
        }
      }
      setLoading(false);
    };

    loadUser();
  }, []);

  const updateUserAttributes = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const userDetails = await paymentApi.getUserDetails();
        
        // Make sure we have valid data before trying to use Object.entries
        if (!userDetails) {
          throw new Error('No user details received');
        }

        // Convert the userDetails into the format expected by Auth
        const attributes = {
          'custom:credits': userDetails.credits.toString(),
          'custom:subscription': userDetails.subscription,
          'email': userDetails.email
        };

        // Now use Object.entries on the formatted attributes object
        const attributeList = Object.entries(attributes).map(([key, value]) => ({
          Name: key,
          Value: value
        }));

        // Update the user state
        setCredits(userDetails.credits);
        setSubscription(userDetails.subscription);
        
        resolve(attributeList);
      } catch (error) {
        console.error('Error in updateUserAttributes:', error);
        reject(error);
      }
    });
  };

  const signUp = (email, password, firstName, lastName) => {
    return new Promise((resolve, reject) => {
      const attributeList = [
        new CognitoUserAttribute({ Name: 'email', Value: email }),
        new CognitoUserAttribute({ Name: 'given_name', Value: firstName }),
        new CognitoUserAttribute({ Name: 'family_name', Value: lastName }),
        new CognitoUserAttribute({ Name: 'custom:credits', Value: '0' }),
        new CognitoUserAttribute({ Name: 'custom:subscription', Value: 'FREE' })
      ];

      userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  };

  const login = (email, password) => {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: async (result) => {
          try {
            // Get user attributes after successful login
            await new Promise((resolve, reject) => {
              cognitoUser.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                  return;
                }
                
                const userAttributes = {};
                attributes.forEach(attr => {
                  userAttributes[attr.Name] = attr.Value;
                });

                setUser(cognitoUser);
                setFirstName(userAttributes['given_name'] || null);
                setEmail(userAttributes['email'] || null);
                setCredits(Number(userAttributes['custom:credits'] || 0));
                setSubscription(userAttributes['custom:subscription'] || 'FREE');
                setSubscriptionStartDate(userAttributes['custom:subscriptionStartDate'] || null);
                setSub(userAttributes['sub'] || null);
                resolve();
              });
            });
            resolve(result);
          } catch (error) {
            reject(error);
          }
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };

  const logout = () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      setUser(null);
      setFirstName(null);
      setEmail(null);
      setCredits(0);
      setSubscription('FREE');
      setSubscriptionStartDate(null);
      setSub(null);
    }
  };

  const verifyEmail = (email, code) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
      });

      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  };

  const resendVerificationCode = (email) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
      });

      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(result);
      });
    });
  };
  const forgotPassword = (email) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
      });
  
      cognitoUser.forgotPassword({
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };
  
  const confirmPassword = (email, code, newPassword) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userPool
      });
  
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: () => {
          resolve();
        },
        onFailure: (err) => {
          reject(err);
        }
      });
    });
  };
  
  const changePassword = (oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
      const cognitoUser = userPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user logged in'));
        return;
      }
  
      cognitoUser.getSession((err, session) => {
        if (err || !session.isValid()) {
          reject(new Error('User is not authenticated'));
          return;
        }
  
        cognitoUser.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(result);
        });
      });
    });
  };

  return (
    <AuthContext.Provider value={{
      user,
      firstName,
      email,
      credits,
      subscription,
      subscriptionStartDate,
      sub,
      login,
      logout,
      signUp,
      verifyEmail,
      resendVerificationCode,
      updateUserAttributes,
      forgotPassword,
      confirmPassword,
      changePassword,
      loading
    }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);