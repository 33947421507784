import { api } from './authenticationApi'; 

export const paymentApi = {
  
  createCheckoutSession: async (amount, credits, task) => {
    if (!amount || !credits) {
      throw new Error('Amount and credits are required');
    }
    const response = await api.makeAuthenticatedRequest('/payments/create-checkout-session', {
      method: 'POST',
      body: { amount, credits, task }
    });
    return response.sessionId;
  },
  
  createSubscriptionSession: async (selectedPlan) => {
    const response = await api.makeAuthenticatedRequest('/subscriptions/create-subscription-session', {
      method: 'POST',
      body: { selectedPlan }
    });
    return response;
  },
   
  createPaymentIntent: async (amount, credits, task) => {
    if (!amount || !credits) {
      throw new Error('Amount and credits are required');
    }
    const response = await api.makeAuthenticatedRequest('/payments/create-payment-intent', {
      method: 'POST',
      body: { amount, credits, task }
    });
    return response;
  },

  createSubscription: async (selectedPlan, paymentMethodId, userEmail) => {
    try {
      const response = await api.makeAuthenticatedRequest('/subscriptions/create-subscription', {
        method: 'POST',
        body: {
          selectedPlan,
          paymentMethod: { id: paymentMethodId },
          userEmail
        }
      });

      // Add error logging
      console.log('Subscription API Response:', response);

      if (!response) {
        throw new Error('No response received from server');
      }

      return response;  // This should include either clientSecret or status: 'subscription_updated'
    } catch (error) {
      console.error('Subscription API Error:', error);
      throw error;
    }
  },

  getUserDetails: async () => {
    const response = await api.makeAuthenticatedRequest('/payments/user-details', {
      method: 'GET'
    });
    
    if (!response) {
      throw new Error('Failed to fetch user details');
    }
    
    return response;
  },

  cancelSubscription: async () => {
    const response = await api.makeAuthenticatedRequest('/subscriptions/cancel-subscription', {
      method: 'POST'
    });
    return response;
  },

  removeCancellation: async (subscriptionId) => {
    const response = await api.makeAuthenticatedRequest('/subscriptions/remove-cancellation', {
      method: 'POST',
      body: { subscriptionId }
    });
    return response;
  }
};